/* This file is auto generated by icon/build.js; DO NOT EDIT. */

@font-face {
  font-family: "hec-eye-icons";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../webfonts/hec-eye-icons.eot");
  src: url("../webfonts/hec-eye-icons.eot?#iefix") format("embedded-opentype"), url("../webfonts/hec-eye-icons.woff2") format("woff2"), url("../webfonts/hec-eye-icons.woff") format("woff"), url("../webfonts/hec-eye-icons.ttf") format("truetype"), url("../webfonts/hec-eye-icons.svg#hec-eye-icons") format("svg");
}

.icon.he-icon {
  display: inline-block;
  font-family: "hec-eye-icons";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}


.icon.he-icon-marker-plus::before {
  content: "\ea01";
}

.icon.he-icon-stream::before {
  content: "\ea02";
}

.icon.he-icon-aed::before {
  content: "\ea03";
}

.icon.he-icon-airport::before {
  content: "\ea04";
}

.icon.he-icon-amusement-park::before {
  content: "\ea05";
}

.icon.he-icon-aquarium::before {
  content: "\ea06";
}

.icon.he-icon-bicycle::before {
  content: "\ea07";
}

.icon.he-icon-blocked::before {
  content: "\ea08";
}

.icon.he-icon-building::before {
  content: "\ea09";
}

.icon.he-icon-bus::before {
  content: "\ea0a";
}

.icon.he-icon-bus-stop::before {
  content: "\ea0b";
}

.icon.he-icon-camp::before {
  content: "\ea0c";
}

.icon.he-icon-car::before {
  content: "\ea0d";
}

.icon.he-icon-cattle::before {
  content: "\ea0e";
}

.icon.he-icon-caution::before {
  content: "\ea0f";
}

.icon.he-icon-collapse::before {
  content: "\ea10";
}

.icon.he-icon-confirmation::before {
  content: "\ea11";
}

.icon.he-icon-construction::before {
  content: "\ea12";
}

.icon.he-icon-damaged::before {
  content: "\ea13";
}

.icon.he-icon-evacuation-area::before {
  content: "\ea14";
}

.icon.he-icon-farm::before {
  content: "\ea15";
}

.icon.he-icon-ferry::before {
  content: "\ea16";
}

.icon.he-icon-fire::before {
  content: "\ea17";
}

.icon.he-icon-fire-brigade-station::before {
  content: "\ea18";
}

.icon.he-icon-fire-cistern::before {
  content: "\ea19";
}

.icon.he-icon-fire-extinguisher::before {
  content: "\ea1a";
}

.icon.he-icon-fire-hydrant::before {
  content: "\ea1b";
}

.icon.he-icon-flood::before {
  content: "\ea1c";
}

.icon.he-icon-gas-cutoff::before {
  content: "\ea1d";
}

.icon.he-icon-gas-station::before {
  content: "\ea1e";
}

.icon.he-icon-heritage::before {
  content: "\ea1f";
}

.icon.he-icon-hospital::before {
  content: "\ea20";
}

.icon.he-icon-hotel::before {
  content: "\ea21";
}

.icon.he-icon-information::before {
  content: "\ea22";
}

.icon.he-icon-landslide::before {
  content: "\ea23";
}

.icon.he-icon-leakage::before {
  content: "\ea24";
}

.icon.he-icon-missing::before {
  content: "\ea25";
}

.icon.he-icon-museum::before {
  content: "\ea26";
}

.icon.he-icon-natural-water::before {
  content: "\ea27";
}

.icon.he-icon-park::before {
  content: "\ea28";
}

.icon.he-icon-parking::before {
  content: "\ea29";
}

.icon.he-icon-police::before {
  content: "\ea2a";
}

.icon.he-icon-post-office::before {
  content: "\ea2b";
}

.icon.he-icon-restaurant::before {
  content: "\ea2c";
}

.icon.he-icon-retirement-home::before {
  content: "\ea2d";
}

.icon.he-icon-rice::before {
  content: "\ea2e";
}

.icon.he-icon-school::before {
  content: "\ea2f";
}

.icon.he-icon-shelter::before {
  content: "\ea30";
}

.icon.he-icon-shopping::before {
  content: "\ea31";
}

.icon.he-icon-shrine::before {
  content: "\ea32";
}

.icon.he-icon-smoking-area::before {
  content: "\ea33";
}

.icon.he-icon-spa::before {
  content: "\ea34";
}

.icon.he-icon-station::before {
  content: "\ea35";
}

.icon.he-icon-taxi::before {
  content: "\ea36";
}

.icon.he-icon-temple::before {
  content: "\ea37";
}

.icon.he-icon-toilet::before {
  content: "\ea38";
}

.icon.he-icon-traffic-accident::before {
  content: "\ea39";
}

.icon.he-icon-vacant-house::before {
  content: "\ea3a";
}

.icon.he-icon-water-cutoff::before {
  content: "\ea3b";
}

.icon.he-icon-wifi::before {
  content: "\ea3c";
}

.icon.he-icon-zoo::before {
  content: "\ea3d";
}

