@use "../../../styles/variables/size";
@use "../../../styles/variables/zIndex";

.root:global(.ui.menu.fixed) {
  height: size.$header-menu-height;
  z-index: zIndex.$z-index-header-menu;
}

.logo {
  height: calc(#{size.$header-menu-height} - 25px);
}
