// menus
$header-menu-height: 55px;

// side-panel
$side-panel-width: 350px;
$side-panel-margin: 10px;
$side-panel-menu-height: 40px;
$side-panel-font-size: 1.5rem;
$footer-height: calc(55px + env(safe-area-inset-bottom));

// mobile
$mobile-footer-height: calc(55px + env(safe-area-inset-bottom));
$mobile-top-sheet-max-height: 50vh;
