@use "../classes/shadow";
@use "../variables/size";

:global {
  .leaflet-control-layers:not(.leaflet-control-layers-expanded) {
    border: none;
    box-shadow: none;
    background: transparent;
    border-radius: initial;
  }

  .leaflet-control-layers .leaflet-control-layers-button {
    margin: 0;
  }

  .leaflet-control-layers-expanded .leaflet-control-layers-button {
    display: none;
  }

  .leaflet-control-container > .leaflet-top {
    top: size.$header-menu-height;
  }

  .leaflet-control.ui.button,
  .leaflet-control > .ui.button {
    @extend %map-item-shadow;
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    @extend %map-item-shadow;
  }
}
